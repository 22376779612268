.btn-login {
  font-weight: bold;
}

.imgLogoLogin {
  padding-bottom: 70px;
}

.btn-login:hover {
  background-color: #171332 !important;
  border-color: #171332 !important;
  color: #3CDFB3 !important;
  font-weight: bold;
}

.cardLogin {
  background-color: transparent;
  border: 0;
  box-shadow: none;
}

.cardLogin:hover {
  border: 0;
  box-shadow: none;
}

.input-group > .form-control {
    background-color: #fff;
    height: 70px;
    color: #171332 !important;
    font-weight: bold;
    border: none; 
  }

  .form-controlLogin {
 
    letter-spacing: 3px;
  }

  .input-group > .form-control:focus {
   border: 1px 1px 1px 1px solid #171332!important;
   background-image: none;
   border: 1px solid #171332;
  }

.input-group > .form-control::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */

  color: #171332 !important;
  font-weight: bold;
}
.input-group > .form-control::-moz-placeholder {
  /* Firefox 19+ */

  color: #171332 !important;
  font-weight: bold;
}
.input-group > .form-control:-ms-input-placeholder {
  /* IE 10+ */

  color: #171332 !important;
  font-weight: bold;
}
.input-group > .form-control:-moz-placeholder {
  /* Firefox 18- */

  color: #171332 !important;
  font-weight: bold;
}
.link-portafolio {
  width: 100%;
  font-size: 16px;
  font-weight: 700;
  font-family: "Montserrat";
  color: #171332;
}
.link-portafolio > a {
  font-size: 18px;
  color: #3cdfb3;
}
 