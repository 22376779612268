.HeaderOPC1 {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding-bottom: 20px;
}
.HeaderOPC1 > .HeaderOPC1_row1 {
  justify-content: center;
  text-align: center;
  align-items: center;
  vertical-align: middle;
  font-size: 35px;
  font-weight: 100;
}
.HeaderOPC1 > .HeaderOPC1_row1 > img {
  height: 40px;
}

.HeaderOPC1 > .HeaderOPC1_row2 {
  font-weight: 600;
  font-size: 1.5rem;
}
.HeaderOPC1 > .HeaderOPC1_row3 {
  font-weight: 500;
}

.HeaderOPC1 > .HeaderOPC1_row4 {
  display: flex;
  justify-content: space-around;
  width: 100%;
  height: 100%;
  flex-direction: row;
}
.HeaderOPC1 > .HeaderOPC1_row4 > div {
  width: 50%;
}
.HeaderOPC1 > .HeaderOPC1_row4 > div:nth-child(2) {
  display: flex;
  align-items: center;
  vertical-align: middle;
}
.HeaderOPC1 > .HeaderOPC1_row4 > div > img {
  width: 100%;
  max-width: 250px;
}

.rowOpc {
  display: flex;
  flex-direction: column;
  padding-bottom: 40px;
}
.opcText > p {
  font-weight: 600;
}
.colOpcion {
  padding-top: 10px;
  font-weight: 500;
}

@media (max-width: 600px) {
  .HeaderOPC1 > .HeaderOPC1_row4 {
    flex-direction: column;
  }
  .HeaderOPC1 > .HeaderOPC1_row4 > div > img {
    max-width: 250px;
  }

  .HeaderOPC1 > .HeaderOPC1_row4 > div {
    display: flex;
    align-items: center;
    vertical-align: middle;
    width: 100%;
    align-items: center;
    justify-content: center;
  }
}

.tooltip-dividendos {
  width: 250px;
  border: 1px black solid !important;
}

.tooptip-title {
  font-size: 0.8rem !important;
  font-weight: 900 !important;
  text-align: justify;
}
.tooptip-body {
  font-size: 0.8rem !important;
  text-align: justify;
}
