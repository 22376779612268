.ui-pnotify{
    direction: ltr !important;
}

.cardContrasena{
    background-color: transparent;
    box-shadow: none;
}
.cardContrasena:hover{
    box-shadow: none;
}