// =======================================
//     List of variables for layout
// =======================================
/*
    description Of variables for build for theme layouts
        1) menu-caption-color
            List of color for sidebar menu caption

        2) brand-background
            List of color for logo background

        3) header-dark-background
            List of color for Dark Header

        4) header-light-background
            List of color for light Header

        5) menu-dark-background
            List of color for Dark sidebar menu

        6) menu-light-background
            List of color for light sidebar menu

        7) menu-active-color
            List of color for Active item highliter

        8) menu-icon-color
            List of color for sidebar menu items icon colors
*/
$header-height: 50px;
// $Menu-width: 250px;
$Menu-width: 115px;
$Menu-collapsed-width: 115px;

// color list for build layouts
$backgroud-color-login: #f5f7f8;
$primary-color: #171332;
$warning-color: #F5D624;
$danger-color: #ff3333;
$success-color: #3CDFB3;
$purple-color: #536dfe;
$info-color: #1dbfbf;
$secondary-color: #748892;
$default-color: #F5F6F8;
$light-color: #F5F6F8;
$dark-color: #000;
$inverse-color: $dark-color;
$theme-border: #F5F6F8;

// header
$header-dark-background: #171332;
$header-dark-text-color: #fff;
$header-light-background: #fff;
$header-light-text-color: #171332;

// Menu
$menu-dark-background: #171332;
$menu-dark-text-color: #97a7c1;

$menu-light-background: #fff;
$menu-light-text-color: #535763;
$menu-active-color: $warning-color;

$dark-layout: darken(#000, 8%);
$dark-layout-font: #adb7be;

// Menu icon
$menu-icon-color: #171332, #ff3333, #3CDFB3, #536dfe, #1dbfbf, $warning-color;

// Header background
$color-header-name: blue, red, purple, info, green, dark;
$color-header-color: $primary-color, $danger-color, $purple-color, $info-color,
  $success-color, #171332;
// Header gradient
$color-header-grd-name: grd-blue, grd-red, grd-purple, grd-info, grd-green,
  grd-dark;
$color-header-grd-color: linear-gradient(
    to right,
    $primary-color 50%,
    $light-color 100%
  ),
  linear-gradient(to right, $danger-color 0%, #ff9a44 100%),
  linear-gradient(to right, $purple-color 0%, #00adff 100%),
  linear-gradient(to right, $info-color 0%, #1dbfbf 100%),
  linear-gradient(to right, $success-color 0%, #fff 100%),
  linear-gradient(to right, #171332 0%, #1f3054 100%);

// Active background color
$color-active-name: blue, red, purple, info, dark;
$color-active-color: #171332, #ff3333, #536dfe, #3CDFB3, $dark-color;

// Menu title color
$color-title-name: blue, red, purple, info, dark;
$color-title-color: #171332, #ff3333, #536dfe, #3CDFB3, $dark-color;

$theme-font-family: "Montserrat", sans-serif;
$theme-font-size: 14px;
$theme-background: #F5F6F8;
$theme-font-color: $primary-color; //change
$theme-heading-color: #37474f;

$color-name: c-blue, c-red, c-green, c-yellow, c-purple, c-gray;
$color-color: $primary-color, $danger-color, $success-color, $warning-color,
  $purple-color,$light-color;
$color-bt-name: primary, danger, success, warning, info, purple;
$color-bt-color: $primary-color, $danger-color, $success-color, $warning-color,
  $info-color, $purple-color;

// form-control variables
$form-bg: $light-color;

// social color variables
$social-name: facebook, twitter, dribbble, pinterest, youtube, googleplus,
  instagram, viber, behance, dropbox, linkedin;
$social-color: #3c5a99, #42c0fb, #ec4a89, #bf2131, #e0291d, #c73e2e, #aa7c62,
  #7b519d, #0057ff, #3380ff, #0077b5;

// =====================================================================
// ==================   Bootstrap Extra Variable  ======================
// =====================================================================

// Color contrast
$yiq-contrasted-threshold: 200;
$yiq-dark-color: #37474f;
$yiq-light-color: #fff;
$theme-color-interval: 8%;
$alert-bg-level: -10;
$alert-border-level: -9;
$alert-color-level: 6;

// card block css
$card-shadow: 0 2px 10px -1px rgba(69, 90, 100, 0.3);
$card-header-border: 1px solid #e2e5e8;
