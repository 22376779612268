.tab-user-card {
  .nav-pills {
    background: $light-color;
    padding: 20px;
    border-radius: 5px;
  }
}

.dataTables_wrapper .dataTables_paginate .paginate_button.active {
  background: $primary-color;
  border-color: $primary-color;
}
.navbar-wrapper {
  .navbar-content {
    &.next-scroll {
      height: 100vh;
    }
  }
}
 

.btn-cierreUserDrop > i {
  margin: 0 10px !important;
}

.btn-cierreUserDrop{
  text-align: left;
  padding-top: 10px!important;
  padding-bottom: 10px!important;
  background: linear-gradient(to right, red 50%, #171332 50%);
  background-size: 200% 100%;
  background-position: right bottom;
  transition: all 0.5s ease-out;

}
.btn-cierreUserDrop:hover {
  background-position: left bottom;
}

.li-cierreUserDrop{
  background-color: transparent !important;
  line-height: 1.2;
}


.form-buttonPortafolioDark{
  background-color: #3cdfb3!important;
  border-radius: 30px;
  margin-left: 15px!important;
  margin-right: 15px!important;
  color: #171332 !important;
  border: none;
  padding-left: 40px;
  padding-right: 40px;
}

.form-buttonPortafolio{
  border-radius: 30px;
  margin-left: 15px!important;
  margin-right: 15px!important;
  padding-left: 40px;
  padding-right: 40px;
}


.form-buttonPortafolioDark:hover {
  background-color: #171332!important;
  border-color: #171332!important;
  color: #3cdfb3!important;
}


.link-portafolioLogin {
  font-size: 12px;
  font-weight: 700;
  font-family: "Montserrat";
  color: #171332;
}
.link-portafolioLogin > span {
  font-size: 12px;
  color: #3cdfb3;
  cursor: pointer;
}

.btn-descarga {
  border: none;
  color: #171332!important;
  background-color: #3cdfb3;
  margin-left: 0 !important;
  border-radius: 30px;
  padding-left: 55px;
  padding-right: 55px;
  padding-top: 15px;
  padding-bottom: 15px;
}


.btn-descarga:hover {
  color: white !important;
}
 