.form-control {
  background-color: #fff;
  color: #171332 !important;
  font-weight: bold;
  border: 1px solid #70707096;
  font-size: 0.875rem;
}

.form-control:focus {
  background-image: none;
  border: 1px solid #70707096 !important;
}

.form-control::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */

  color: #ced4da !important;
  font-weight: bold;
}
.form-control::-moz-placeholder {
  /* Firefox 19+ */

  color: #171332 !important;
  font-weight: bold;
  opacity: 0.5;
}
.form-control:-ms-input-placeholder {
  /* IE 10+ */

  color: #171332 !important;
  font-weight: bold;
}
.form-control:-moz-placeholder {
  /* Firefox 18- */

  color: #171332 !important;
  font-weight: bold;
}

.form-controlPortafolioFormulario {
  border-radius: 30px;
  height: 55px;
  padding: 0.625rem 1.1875rem !important;
}
.colOpcion {
  padding-top: 10px;
  font-weight: 500;
}

.ml-0 {
  margin-left: 0 !important;
}

.HeaderFormularioCls {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin: 0;
}

.rowOpc {
  display: flex;
  flex-direction: column;
}
.opcText > p {
  font-weight: 600;
}

.form-group-checkbox-pd {
  padding-left: 20px;
  padding-right: 20px;
}
.form-group-checkbox-pd > .form-check > input[type="checkbox"] {
  accent-color: #171332;
}
.was-validated .form-check-input:valid ~ .form-check-label,
.form-check-input.is-valid ~ .form-check-label {
  color: #171332;
}

.subsection-video-portafolio-dividendo {
  padding-top: 30px;
  padding-bottom: 30px;
}

.font-size-subtitle {
  font-size: 1.5rem;
}
.inputfile-custom {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}
.inputfile-custom + label {
  font-size: 1.25em;
  font-weight: 700;
  color: black;
  display: flex;
  justify-content: center;
  vertical-align: middle;
}

.inputfile-custom :focus + label,
.inputfile-custom + label:hover {
  background-color: red;
}

.icon-feather-portafolio {
  font-size: 2rem;
  padding-right: 10px;
}
.form-controlPortafolioInputCustom{
  display: flex;
  flex-direction: row;
}
.form-controlPortafolioInputCustom > span:nth-child(2){
  display: flex ;
  flex-direction: column;
  justify-content: center;
}

.form-controlPortafolioInputCustom:hover {
  background-color: #171332!important;
  border-color: #171332!important;
  color: #3cdfb3!important;
}

.label_justify{
  text-align: justify;
}