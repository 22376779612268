.pass-wrapper {
  direction: ltr;
  width: 100%;
  height: 100%;
}

.pass-form { 
  width: 500px;
  padding-top: 100px;
}

.form-control {
    background-color: #fff;
 
    color: #171332 !important;
    font-weight: bold;
    border: 1px solid #171332;
    font-size: .875rem;
  
  }

 .form-control:focus {
   border: 1px solid #171332!important;
   background-image: none;
   border: 1px solid #171332;
  }

.form-control::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */

  color:  #ced4da!important;
  font-weight: bold;
}
 .form-control::-moz-placeholder {
  /* Firefox 19+ */

  color: #171332 !important;
  font-weight: bold;
  opacity: 0.5;
}
 .form-control:-ms-input-placeholder {
  /* IE 10+ */

  color: #171332 !important;
  font-weight: bold;
}
 .form-control:-moz-placeholder {
  /* Firefox 18- */

  color: #171332 !important;
  font-weight: bold;
}

.form-controlPortafolio{
    border-radius: 30px;
    height: 65px;
    padding: .625rem 1.1875rem !important;
    letter-spacing: 3px;
}

.form-buttonPortafolioDark{
    background-color: #3cdfb3!important;
    border-radius: 30px;
    margin-left: 15px!important;
    margin-right: 15px!important;
    color: #171332 !important;
    border: none;
    padding-left: 40px;
    padding-right: 40px;
}

.form-buttonPortafolio{
    border-radius: 30px;
    margin-left: 15px!important;
    margin-right: 15px!important;
    padding-left: 40px;
    padding-right: 40px;
}

 
.form-buttonPortafolioDark:hover {
    background-color: #171332!important;
    border-color: #171332!important;
    color: #3cdfb3!important;
}